/**
 * Created by razial on 16.01.2025.
 */

var HoseLogic = function () {
    this.currentLocationId = 0;
};

HoseLogic.prototype.reset = function () {
    this.currentLocationId = 0;
};

HoseLogic.prototype.getMainLocation = function () {
    var availableLocations = cleverapps.meta.listAvailableLocations();
    return this.currentLocationId > availableLocations[availableLocations.length - 1] ? availableLocations[availableLocations.length - 1] : this.currentLocationId;
};

HoseLogic.prototype.getLocationClass = function () {
    return HoseLocation;
};

HoseLogic.prototype.getMainScene = function () {
    return HoseScene;
};

HoseLogic.prototype.listAvailableLocations = function () {
    var ids = [];
    var id = 0;

    var exist = function () {
        var bundleName = "episode_" + id;

        if (cleverapps.config.type === "board" && cleverapps.settings.language !== "en") {
            bundleName += "_" + cleverapps.settings.language;
        }

        return bundles[bundleName] && !bundles[bundleName].meta.debug;
    };

    while (exist()) {
        ids.push(id);
        id++;
    }

    return ids;
};

HoseLogic.prototype.listActiveLocations = function () {
    var locationId = this.currentLocationId;

    var available = cleverapps.meta.listAvailableLocations();
    if (available.length && available[available.length - 1] < locationId) {
        locationId = available[available.length - 1];
    }

    return [locationId];
};

HoseLogic.prototype.isCompleted = function () {
    var available = cleverapps.meta.listAvailableLocations();
    return !available.length || available[available.length - 1] < this.currentLocationId;
};

HoseLogic.prototype.moveNextLocation = function () {
    this.currentLocationId += 1;
};

HoseLogic.prototype.processChatMessage = function () {

};
